/**
 * Created by john on 15/1/17.
 */
'use strict';

/* exported
paymentsCtrl
 */

function paymentsCtrl($scope, payments, user, Restangular, $window, PaymentViewModal, $location, PaymentUploadModal) {
  function initSelectedPayments(payments) {
    $scope.selectedPayments = {};

    payments.forEach(function(payment) {
      $scope.selectedPayments[payment._id] = {selected: false};
    });
  }

  initSelectedPayments(payments);

  $scope.payments = payments;
  $scope.user = user;
  $scope.filters = {
    date: 'created'
  };

  var search = $location.search();

  // On init if reference is selected display it
  if (search.reference) {
    $scope.filters.reference = search.reference;
  }

  if (search.status) {
    $scope.filters.status = search.status;
  }

  // On init if email is selected display it
  if (search.email) {
    $scope.filters.email = search.email;
  }

  // On init if name is selected display it
  if (search.name) {
    $scope.filters.name = search.name;
  }

  // On init if company is selected display it
  if (search.company) {
    $scope.filters.company = {_id: search.company};
  }

  if (search.from) {
    $scope.filters.from = search.from;
  }

  if (search.to) {
    $scope.filters.to = search.to;
  }

  if (search.date) {
    $scope.filters.date = search.date;
  }

  if (search.paymentId) {
    PaymentViewModal.show(search.paymentId, user);
  }


  /**
   * Paging
   */
  // On init if page is above 1

  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.totalItems = $scope.payments.meta.total;

  $scope.pageChanged = function() {
    var limit = 20;
    var offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;

    $scope.payments.getList($scope.filters).then(function(payments) {
      $scope.payments = payments;
      initSelectedPayments(payments);
    });
  };


  $scope.$watch('currentPage', function(page) {
    var search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });

  /**
   * Filters
   */
  $scope.$on('filters.status', function(event, data) {
    $scope.filters.status = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.payments.getList($scope.filters).then(function(payments) {
      $scope.currentPage = 1;
      $scope.payments = payments;
      $scope.totalItems = $scope.payments.meta.total;
    });
  });

  $scope.$on('filters.reference', function(event, data) {
    $scope.filters.reference = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.payments.getList($scope.filters).then(function(payments) {
      $scope.currentPage = 1;
      $scope.payments = payments;
      $scope.totalItems = $scope.payments.meta.total;
      initSelectedPayments(payments);
    });
  });

  $scope.$on('filters.email', function(event, data) {
    $scope.filters.email = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.payments.getList($scope.filters).then(function(payments) {
      $scope.currentPage = 1;
      $scope.payments = payments;
      $scope.totalItems = $scope.payments.meta.total;
      initSelectedPayments(payments);
    });
  });

  $scope.$on('filters.name', function(event, data) {
    $scope.filters.name = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.payments.getList($scope.filters).then(function(payments) {
      $scope.currentPage = 1;
      $scope.payments = payments;
      $scope.totalItems = $scope.payments.meta.total;
      initSelectedPayments(payments);
    });
  });

  $scope.$on('filters.company', function(event, data) {
    $scope.filters.company = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.payments.getList($scope.filters).then(function(payments) {
      $scope.currentPage = 1;
      $scope.payments = payments;
      $scope.totalItems = $scope.payments.meta.total;
      initSelectedPayments(payments);
    });
  });

  $scope.$on('filters.from', function(event, data) {
    $scope.filters.from = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.payments.getList($scope.filters).then(function(payments) {
      $scope.currentPage = 1;
      $scope.payments = payments;
      $scope.totalItems = $scope.payments.meta.total;
      initSelectedPayments(payments);
    });
  });

  $scope.$on('filters.to', function(event, data) {
    $scope.filters.to = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.payments.getList($scope.filters).then(function(payments) {
      $scope.currentPage = 1;
      $scope.payments = payments;
      $scope.totalItems = $scope.payments.meta.total;
      initSelectedPayments(payments);
    });
  });

  $scope.$on('filters.date', function(event, data) {
    $scope.filters.date = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.payments.getList($scope.filters).then(function(payments) {
      $scope.currentPage = 1;
      $scope.payments = payments;
      $scope.totalItems = $scope.payments.meta.total;
      initSelectedPayments(payments);
    });
  });

  /**
   * Functions
   */

  $scope.showUploadModal = function() {
    PaymentUploadModal.show();
  };

  $scope.getRefundStatus = function(payment) {
    if (payment.summary.refunds.amount === payment.summary.charges.amount) {
      return 'refund';
    } else if (payment.summary.refunds.amount > 0 && payment.summary.refunds.amount < payment.summary.charges.amount) {
      return 'partial refund';
    } else {
      return null;
    }
  };

  $scope.getCSV = function() {
    var queryString = Object.assign({}, $scope.filters, {limit: 0, offset: 0});

    Restangular
      .one('payments')
      .get(queryString, {'accept': 'text/csv'})
      .then(function(response) {
        var file = new Blob([response], { type: 'text/csv;charset=utf-8' });
        $window.saveAs(file, 'payments.csv');
      })
      .catch(function() {
        $scope.$emit('notify', {type: 'error', title: 'Download', message: 'Error downloading CSV'});
      });
  };

  $scope.showPayment = function(payment) {
    PaymentViewModal.show(payment._id, user);

    var search = $location.search();
    search.paymentId = payment._id;
    $location.search(search);
  };

  // Bulk paid functions
  $scope.bulkPaidToPartner = function() {
    var payload = [];

    angular.forEach($scope.selectedPayments, function(selectedPayment, key) {
      if (selectedPayment.selected) {
        payload.push({_id: key, status: 'paid'});
      }
    });

    if (payload.length) {
      Restangular.all('payments').customPUT(payload)
        .then(function(bulk) {
          if (bulk.nModified === payload.length) {

            payload.forEach(function(selectedPayment) {
              var currentPayment = _.find($scope.payments, function(payment) {
                return payment._id === selectedPayment._id;
              });

              currentPayment.status = 'paid';
            });

            $scope.$emit('notify', {type: 'success', title: 'Paid', message: 'Mark as paid to Partner'});
            initSelectedPayments($scope.payments);
          }
        })
        .catch(function(error) {
          $scope.$emit('notify', {type: 'error', title: 'Paid', message: '`Mark as paid to Partner` failed ' + error});
        });
    } else {
      $scope.$emit('notify', {type: 'error', title: 'Paid', message: 'You need to select at least one payment'});
    }
  };

  $scope.selectAllDisplayedPayments = function() {
    $scope.payments
      .filter(function(payment) { // Remove already paid to partner
        return payment.status !== 'paid';
      })
      .forEach(function(payment) {
        $scope.selectedPayments[payment._id].selected = true;
      });
  };

  $scope.unselectAllDisplayedPayments = function() {
    Object.keys($scope.selectedPayments)
      .forEach(function(key) {
        $scope.selectedPayments[key].selected = false;
      });
  };

  $scope.allPaymentsArePaid = function() {
    return $scope.payments.every(function(payment) {
      return payment.status === 'paid';
    });
  };

  $scope.getBookingTitle = function(booking) {
    if (booking.items[0].productType === 'line') {
      return booking.items[0].trip.fromId.city.name + ' to ' + booking.items[0].trip.toId.city.name;
    } else {
      return booking.items[0].product.denomination + ' | from ' + booking.items[0].trip.fromId.city.name;
    }
  };
}

'use strict';

/* exported
paymentsMenuCtrl
 */

function paymentsMenuCtrl($scope, $location, $rootScope, moment, user) {
  $scope.filters = {
    date: 'created'
  };

  $scope.user = user;

  // On init
  var search = $location.search();

  if (search.status) {
    $scope.filters.status = search.status;
    $rootScope.$broadcast('filters.status', $scope.filters.status);
  }

  // On init if reference is selected display it
  if (search.reference) {
    $scope.filters.reference = search.reference;
  }

  // On init if email is selected display it
  if (search.email) {
    $scope.filters.email = search.email;
  }

  // On init if email is selected display it
  if (search.name) {
    $scope.filters.name = search.name;
  }

  // On init if company is selected display it
  if (search.company) {
    $scope.filters.company = {_id: search.company};
  }

  if (search.from) {
    $scope.filters.from = new Date(search.from);
  }

  if (search.to) {
    $scope.filters.to = new Date(search.to);
  }

  if (search.date) {
    $scope.filters.date = search.date;
  }

  $scope.filterByStatus = function(status) {
    if ($scope.filters.status === status) {
      $scope.filters.status = null;
    } else {
      $scope.filters.status = status;
    }

    $rootScope.$broadcast('filters.status', $scope.filters.status);

    var search = $location.search();
    search.status = $scope.filters.status;
    $location.search(search);
  };

  $scope.$watch('filters.reference', function(reference, previousValue) {

    if (reference === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.reference', reference);

    var search = $location.search();
    search.reference = reference;
    $location.search(search);
  });

  $scope.$watch('filters.email', function(email, previousValue) {
    if (email === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.email', email);

    var search = $location.search();
    search.email = email;
    $location.search(search);
  });

  $scope.$watch('filters.name', function(name, previousValue) {
    if (name === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.name', name);

    var search = $location.search();
    search.name = name;
    $location.search(search);
  });

  $scope.$watch('filters.company', function(company, previousValue) {
    if (company === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.company', (company || {})._id);

    var search = $location.search();
    search.company = (company || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.from', function(from, previousValue) {
    if (from === previousValue) {
      return;
    }

    var data = from ? moment(from).format('YYYY-MM-DD') : from;

    $rootScope.$broadcast('filters.from', data);

    var search = $location.search();
    search.from = data;
    $location.search(search);
  });

  $scope.$watch('filters.to', function(to, previousValue) {
    if (to === previousValue) {
      return;
    }

    var data = to ? moment(to).format('YYYY-MM-DD') : to;

    $rootScope.$broadcast('filters.to', data);

    var search = $location.search();
    search.to = data;
    $location.search(search);
  });

  $scope.$watch('filters.date', function(date, previousValue) {
    if (date === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.date', date);

    var search = $location.search();
    search.date = date;
    $location.search(search);
  });

  $scope.reset = function() {
    $scope.filters = {
      date: 'created'
    };
  };
}

'use strict';

/* exported
paymentViewModalService
 */

function paymentViewModalService($uibModal, $state, $location) {
  /* jshint validthis: true */
  this.show = function(paymentId, user) {
    $uibModal.open({
      templateUrl: 'views/payments/view.html',
      controller: 'ViewPaymentCtrl',
      resolve: {
        payment: ['Restangular', function(Restangular) {
          return Restangular.one('payments', paymentId).get();
        }],
        user: user
      },
      windowClass: 'booking-view modal-slide-in-right',
      backdrop: true,
      animation: false
    })
      .result
      .then(function() {
        var search = $location.search();
        delete search.paymentId;
        $location.search(search);

      }, function(reason) {
        var search = $location.search();
        delete search.paymentId;
        $location.search(search);

        console.log('Dismiss reason is', reason);

        // if (reason === 'edit') {
        //   $state.go('root.editBooking', {bookingId: bookingId});
        // }
      });
  };

}

'use strict';

/* exported
 paymentUploadModalService
 */

function paymentUploadModalService($uibModal) {

  /* jshint validthis: true */
  this.show = function() {

    $uibModal.open({
      templateUrl: 'views/payments/bulk-upload.html',
      controller: 'PaymentBulkUploadCtrl',
      resolve: {
        company: [function() {
          return null;
        }]
      },
      backdrop: 'static',
      keyboard  : false
    })
      .result.then(function () {
        // ...
      }, function () {
        // ...
      });
  };
}

'use strict';

/* exported
paymentChargeCtrl
 */

function paymentChargeCtrl($scope, booking, transactions, $uibModalInstance) {
  $scope.booking = booking;
  $scope.transactions = transactions;
  $scope.chargeBtnDisable = false;

  $scope.paidByTraveller = $scope.transactions.reduce(function (acc, transaction) {
    return ['charge', 'authorize'].includes(transaction.type) ? acc + transaction.amount : acc - transaction.amount;
  }, 0);

  $scope.dueToSupplier = $scope.booking.price.cost;
  // $scope.dueToSupplier = $scope.transactions.reduce(function (acc, transaction) {
  //   return ['charge', 'authorize'].includes(transaction.type) ? acc + transaction.supplierAmount : acc - transaction.supplierAmount;
  // }, 0);

  if ($scope.paidByTraveller < $scope.booking.price.amount) {
    $scope.charge = {transactionType: 'recoverTransaction'};
  } else {
    $scope.charge = {};
  }

  $scope.close = function () {
    $uibModalInstance
      .dismiss('closed');
  };

  $scope.chargeFn = function () {
    $scope.chargeBtnDisable = true;
    if ($scope.chargeForm.$invalid) {
      $scope.chargeForm.reason.$setTouched();
      $scope.chargeBtnDisable = false;
      return;
    }

    var payload = {
      type: 'charge',
      amount: $scope.charge.transactionType === 'recoverTransaction' ? $scope.booking.price.amount - $scope.paidByTraveller : $scope.charge.amount,
      currency: 'USD',
      reason: $scope.charge.transactionType === 'recoverTransaction' ? 'Recover transaction' : $scope.charge.reason,
      supplierAmount: $scope.charge.transactionType === 'recoverTransaction' ? $scope.booking.price.cost : $scope.charge.supplierAmount,
      isRecover: $scope.charge.transactionType === 'recoverTransaction'
    };

    //Calling to v2 functionality
    booking.route = 'v2/bookings';

    var promise = booking.post('transactions', payload);

    //restoring the route
    booking.route = 'bookings';

    promise
      .then(() => {
        // Temporary hack
        setTimeout(function () {
          location.reload();
        }, 3000);
      })
      .catch((err) => {
        $scope.chargeBtnDisable = false;
        $scope.$emit('notify', {
          type: 'error',
          title: 'Charge',
          message: 'Booking charge failed. <br>' + err.data.message
        });
      });
  };
}

"use strict";

/* exported
paymentChangePriceCtrl
 */

function paymentChangePriceCtrl(
  $scope,
  booking,
  $uibModalInstance,
  CurrenciesService,
  oldBooking,
  moment,
  paymentCycles,
  user,
  _,
  changePriceReasons,
) {
  $scope.changePriceReasons = changePriceReasons;
  $scope.changePriceType = "";
  $scope.changePriceReason = "";
  $scope.booking = booking;
  $scope.oldBooking = oldBooking;
  $scope.paymentCycles = paymentCycles;
  $scope.changePriceBtnDisable = false;
  $scope.effectiveDate = {};
  $scope.chosenCycle = {};

  $scope.doAllowToSplitPrice = booking.items.length === 1;
  $scope.isSetSupplierCostPerItem = false;
  $scope.hasValidationError = false;
  $scope.hasReconcilliationPermission =
    user.additionalScopes.includes('reconciliation');
  $scope.today = moment().toDate();
  $scope.basicCurrency = $scope.booking.basicCurrency;
  $scope.isNotUsd = $scope.basicCurrency !== 'USD';
  $scope.costInSupplierCurrency = _.get(booking, 'price.transportationOnlyCostInSupplierCurrency') || _.get(booking, 'price.costInSupplierCurrency');
  $scope.paidByTraveller = _.get($scope.booking, 'settledPrice.amount');
  $scope.dueToSupplier = $scope.costInSupplierCurrency,

  $scope.changePrice = {
    amount: _.get(booking, 'price.amount'),
    supplierAmount: $scope.costInSupplierCurrency,
    supplierCostPerItem: _.cloneDeep(booking.supplierCostPerItem),
    sumOfItemsCost: $scope.costInSupplierCurrency,
    effectiveDate: {},
  };

  $scope.minDate = moment().subtract(1, 'year').toDate();
  $scope.dateOptions = {
    formatYear: 'yy',
    maxDate: new Date(),
    startingDay: 1,
  };

  const calculationBasedOn = _.get(booking, 'items[0].supplier.calculationBasedOn');
  const invoicePaymentCycle = _.get(booking, 'items[0].supplier.invoicePaymentCycle');
  let currentCycleStart = moment().startOf("month").toDate();
  if (invoicePaymentCycle === 'bi_weekly') {
    const date = moment().date();
    if (date > 15) {
      currentCycleStart = moment(`${moment().format('YYYY-MM')}-16`)
        .startOf('day')
        .toDate();
    }
  }

  $scope.isAllowToChooseNewEffectiveDate = {};
  $scope.booking.items.forEach((item) => {
    const effectiveDate =
    moment(calculationBasedOn === "departure_date"
      ? _.get(item, 'trip.departure.date')
      : $scope.booking.createdAt).startOf('day');

    if (item.supplier.paymentType === 'invoice') {
      if (effectiveDate.isBefore(moment(currentCycleStart)) && !$scope.hasReconcilliationPermission) {
        item.disableItemPriceChange = true;
      }
    }

    if (moment(effectiveDate).isBefore(currentCycleStart)) {
      $scope.isAllowToChooseNewEffectiveDate[item.reference] = true;
    }
  });
  $scope.disableBookingPriceChange = $scope.booking.items.some(item => item.disableItemPriceChange);
  if ($scope.booking.items.length > 1 && $scope.booking.items.some(item => !item.disableItemPriceChange) && !$scope.hasReconcilliationPermission) {
    $scope.isSetSupplierCostPerItem = true;
  }
  $scope.isAllowChooseNewEffectiveDateForTotalCost = Object.values($scope.isAllowToChooseNewEffectiveDate).some(value => value);

  $scope.setIsSplitCostPerItem = function () {
    $scope.isSetSupplierCostPerItem = !$scope.isSetSupplierCostPerItem;
    $scope.booking.items.forEach((item) => {
        $scope.changePrice.effectiveDate[item.reference] = {
          date: null,
        };
      });
    $scope.effectiveDate = {};
  };

  $scope.selectItemCycle = (cycle, item) => {
    $scope.changePrice.effectiveDate[item.reference] = {
      date: cycle ? cycle.end : null
    };
    $scope.chosenCycle[item.reference] = cycle ? cycle.label : null;
  };

  $scope.selectCycle = (cycle) => {
    $scope.booking.items.forEach((item) => {
      $scope.changePrice.effectiveDate[item.reference] = {
        date: cycle ? cycle.end : null
      };
      $scope.chosenCycle[item.reference] = cycle ? cycle.label : null;
    });
    $scope.effectiveDate.date = cycle.end;
    $scope.effectiveDate.cycle = cycle.label;
  };

  $scope.openDatePicker = () => {
    $scope.isShowDatePicker = true;
  };

  $scope.onDateChange = (date) => {
    $scope.booking.items.forEach((item) => {
      $scope.changePrice.effectiveDate[item.reference] = {
        date,
      };
    });
    $scope.effectiveDate.date = date;
  };

  $scope.close = function () {
    $uibModalInstance.dismiss('closed');
  };

  $scope.formatDepartureDate = function (date) {
    return moment(date).format('MMMM d, YYYY HH:mm');
  };

  $scope.$watch('changePrice.amount', function (newValue, oldValue) {
    if (newValue !== undefined) {
      $scope.travelerDiff = Math.abs(
        $scope.paidByTraveller - $scope.changePrice.amount
      );
      $scope.travelerDiffInUsd = Math.abs(
        ($scope.paidByTraveller - $scope.changePrice.amount) / ($scope.booking.selectedCurrencyRate || 1)
      ).toFixed(2);
      $scope.newTotalPriceInUsd = ($scope.changePrice.amount / ($scope.booking.selectedCurrencyRate || 1)).toFixed(2);
      $scope.transactionType =
        $scope.paidByTraveller > $scope.changePrice.amount
          ? 'Refunded'
          : 'Charged';
    } else {
      $scope.travelerDiff = 0;
    }
  });

  $scope.$watch("changePrice.supplierAmount", function (newValue, oldValue) {
    $scope.changePrice.supplierAmountInUsd =
      CurrenciesService.convertWithFixedPrice(
        $scope.changePrice.supplierAmount,
        _.get($scope.booking, 'price.supplierCurrency'),
        "USD"
      );
  });

  $scope.$watch(
    'changePrice.supplierCostPerItem',
    function (newValue, oldValue) {
      const itemsPrices = Object.values($scope.changePrice.supplierCostPerItem);
      const isValidationError = itemsPrices.some(
        (value) => value.costInSupplierCurrency < 0
      );
      if (isValidationError) {
        $scope.hasValidationError = true;
        $scope.changePriceBtnDisable = true;
      } else {
        $scope.hasValidationError = false;
        $scope.changePriceBtnDisable = false;
        $scope.changePrice.sumOfItemsCost = itemsPrices.reduce((sum, value) => {
          return (sum += value.costInSupplierCurrency);
        }, 0);
      }
    },
    true
  );

  $scope.changePriceFn = function () {
    $scope.changePriceBtnDisable = true;
    if ($scope.changePriceForm.$invalid) {
      $scope.changePriceForm.reason.$setTouched();
      $scope.changePriceBtnDisable = false;
      return;
    }

    const payload = {
      amount: $scope.changePrice.amount,
      reason: ($scope.effectiveDate && $scope.effectiveDate.date) ? `Reconciliation for ${$scope.effectiveDate.cycle || $scope.effectiveDate.date}. ${$scope.changePrice.reason}` :  $scope.changePrice.reason,
      supplierCurrency: _.get($scope.booking, 'price.supplierCurrency'),
      currency: "USD",
    };

    if ($scope.isSetSupplierCostPerItem) {
      payload.supplierCostPerItem = $scope.changePrice.supplierCostPerItem;
    } else {
      const supplierCostDiff =
        $scope.changePrice.supplierAmount -
        $scope.costInSupplierCurrency;
      const supplierCostDiffPerItem = supplierCostDiff / booking.items.length;
      const blockChangePrice = Object.keys(
        $scope.changePrice.supplierCostPerItem
      ).some(
        (key) =>
          booking.supplierCostPerItem[key].costInSupplierCurrency +
            supplierCostDiffPerItem <
          0
      );
      if (blockChangePrice) {
        $scope.$emit("notify", {
          type: "error",
          title: "Charge",
          message:
            "New price causes negative items price. Please, change price of each item separatly.",
        });
        $scope.changePriceBtnDisable = false;
        return;
      }
      payload.supplierAmount = $scope.changePrice.supplierAmount;
      payload.changeType = $scope.changePriceType;
      payload.chosenReason = $scope.changePriceReason || $scope.changePrice.reason;
      payload.reason = $scope.changePrice.reason || payload.chosenReason;

      if (!payload.changeType ||  !payload.chosenReason) {
        $scope.$emit("notify", {
          type: "error",
          title: "Price change reason",
          message:
            "Please provide both price change type and reason.",
        });
        $scope.changePriceBtnDisable = false;
        return;
      }
    }

    $scope.booking.items.forEach(item => {
      if ($scope.changePrice.effectiveDate[item.reference] && $scope.changePrice.effectiveDate[item.reference].date) {
        $scope.changePrice.effectiveDate[item.reference].reason = 
        `Reconciliation for ${$scope.chosenCycle[item.reference] || $scope.changePrice.effectiveDate[item.reference].date}. ${[$scope.changePrice.reason]}`
      } 
    });
    payload.effectiveDate = $scope.changePrice.effectiveDate;

    //Calling to v2 functionality

    booking.route = "v2/bookings";

    var promise = booking.post("transactions", payload);

    //restoring the route
    booking.route = "bookings";

    promise
      .then(function (booking) {
        $uibModalInstance.close(booking);
      })
      .catch(function (err) {
        $scope.changePriceBtnDisable = false;
        $scope.$emit("notify", {
          type: "error",
          title: "Charge",
          message: "Booking charge failed. <br>" + err.data.message,
        });
        $uibModalInstance.close(booking);
      });
  };
}

"use strict";

/* exported
changePriceReasonsSelectorDirective
 */

function changePriceReasonsSelectorDirective() {
  return {
    restrict: "EA",
    scope: {
      typesAndReasons: "=",
      selectedType: "=",
      selectedReason: "=",
      isShowFreeTextReason: "=",
      title: "@",
    },
    templateUrl: "views/payments/change-price-reasons.html",
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      scope.typesAndReasonsArr = Object.values(scope.typesAndReasons);

      scope.onChange = function () {
        const selectedItem = scope.typesAndReasonsArr.find(
          (el) => el.value === scope.selectedType
        );
        if (selectedItem) {
          scope.reasons = selectedItem.reasons;
        }
      };
    },
  };
}

'use strict';

/* exported
paymentRefundCtrl
 */

function paymentRefundCtrl($scope, booking, transactions, refundSource, $uibModalInstance, Restangular, $analytics) {
  $scope.booking = booking;
  $scope.transactions = transactions;
  $scope.refund = {};
  $scope.refundBtnDisable = false;

  $scope.paidByTraveller = $scope.transactions.reduce(function(acc, transaction) {
    return ['charge', 'authorize'].includes(transaction.type) ? acc + transaction.amount : acc - transaction.amount;
  }, 0);

  $scope.dueToSupplier = $scope.booking.price.cost;
  // $scope.dueToSupplier = $scope.transactions.reduce(function(acc, transaction) {
  //   return ['charge', 'authorize'].includes(transaction.type) ? acc + transaction.supplierAmount : acc - transaction.supplierAmount;
  // }, 0);

  if ($scope.paidByTraveller > $scope.booking.price.amount) {
    $scope.refund = {transactionType: 'recoverTransaction'};
  } else {
    $scope.refund = {};
  }

  $scope.close = function() {
    $uibModalInstance
      .dismiss('closed');
  };

  $scope.refundFn = function() {
    $scope.refundBtnDisable = true;
    if ($scope.refundForm.$invalid) {
      $scope.$emit('notify', {
        type: 'error',
        title: 'Missing information',
        message: 'All fields are required. <br> Refund not sent, please fill everything.'
      });
      $scope.refundForm.reason.$setTouched();
      $scope.refundBtnDisable = false;
      return;
    }

    var payload = {
      type: 'refund',
      amount: $scope.refund.transactionType === 'recoverTransaction' ? $scope.paidByTraveller - $scope.booking.price.amount : $scope.refund.amount,
      currency: 'USD',
      reason: $scope.refund.transactionType === 'recoverTransaction' ? 'Recover transaction' : $scope.refund.reason,
      supplierAmount: $scope.refund.transactionType === 'recoverTransaction' ? $scope.booking.price.cost : $scope.refund.supplierAmount,
      isRecover: $scope.refund.transactionType === 'recoverTransaction'
    };

    //Calling to v2 functionality
    booking.route = 'v2/bookings';
    booking.post('transactions', payload)
      .then(function(transaction) {
        // Temporary hack
        setTimeout(function() {
          location.reload();
        },3000);

        // $scope.$emit('notify', {type: 'success', title: 'Refund', message: 'Booking refunded with Success'});
        //
        // // Send to mixpanel only if there was a refund
        // try {
        //     $analytics.eventTrack('Refund', {
        //       'Product ID': booking.items[0].line.ecommerce.productId,
        //       'Product Name': booking.items[0].line.ecommerce.name,
        //       'Company': booking.items[0].company.name,
        //       'Countries': booking.items[0].from.city.country !== booking.items[0].to.city.country ?
        //         [booking.items[0].from.city.country, booking.items[0].to.city.country] :
        //         [booking.items[0].from.city.country],
        //       'Cities': [booking.items[0].from.city.name, booking.items[0].to.city.name],
        //       'Amount': $scope.refund.amount,
        //       'Supplier Amount': $scope.refund.supplierAmount,
        //       'Refund Type': 'Partial',
        //       'Reason': $scope.refund.reason,
        //       'Source': refundSource
        //     });
        // } catch (e) {
        //   console.log('Error with mixpanel', e);
        // }
        //
        // if (transactions) {
        //   transactions.push(transaction);
        //   $uibModalInstance.close(payment);
        // } else {
        //   $uibModalInstance.close(transaction);
        // }

      })
      .catch((err) => {
        $scope.refundBtnDisable = false;
        $scope.$emit('notify', {type: 'error', title: 'Refund', message: 'Booking refund failed. <br>' + err.data.message});
      });

    //restoring the route
    booking.route = 'bookings';
  };
}

/**
 * Created by john on 17/1/17.
 */
'use strict';

/* exported
viewPaymentCtrl
 */

function viewPaymentCtrl($scope, payment, user, Restangular, $uibModalInstance, $uibModal) {
  $scope.payment = payment;
  $scope.user = user;

  $scope.charge = function() {
    $uibModal
      .open({
        templateUrl: 'views/payments/charge.html',
        controller: 'PaymentChargeCtrl',
        resolve: {
          booking: [function() {
            return $scope.payment.booking;
          }],
          user: user,
          payment: $scope.payment,
          chargeSource: [function() {
            return 'Payment View';
          }]
        }
      })
      .result
      .then(function(payment) {
        $scope.payment = payment;
      });
  };

  $scope.refund = function() {
    $uibModal
      .open({
        templateUrl: 'views/payments/refund.html',
        controller: 'PaymentRefundCtrl',
        resolve: {
          booking: [function() {
            return $scope.payment.booking;
          }],
          user: user,
          payment: $scope.payment,
          refundSource: [function() {
            return 'Payment View';
          }]
        }
      })
      .result
      .then(function(payment) {
        $scope.payment = payment;
      });
  };

  $scope.markAs = function(status) {
    var updatedPayment = Restangular.copy($scope.payment);

    updatedPayment.status = status;
    updatedPayment
      .put()
      .then(function(updatedPayment) {
        $scope.payment = updatedPayment;
        $scope.$emit('notify', {type: 'success', title: 'Paid', message: 'Mark as ' + status + ' to Partner'});
      })
      .catch(function(err) {
        $scope.$emit('notify', {type: 'error', title: 'Paid', message: '`Mark as ' + status + ' to Partner` failed ' + JSON.stringify(err)});
      });
  };

  $scope.close = function() {
    $uibModalInstance
      .dismiss('closed');
  };

  $scope.getRefundStatus = function(payment) {
    if (payment.summary.refunds.amount === payment.summary.charges.amount) {
      return 'refund';
    } else if (payment.summary.refunds.amount > 0 && payment.summary.refunds.amount < payment.summary.charges.amount) {
      return 'partial refund';
    } else {
      return null;
    }
  };

  $scope.getBookingTitle = function(booking) {
    if (booking.items[0].productType === 'line') {
      return booking.items[0].trip.fromId.city.name + ' to ' + booking.items[0].trip.toId.city.name;
    } else {
      return booking.items[0].product.denomination + ' | from ' + booking.items[0].trip.fromId.city.name;
    }
  };
}
